import styled, { ThemeContext } from 'styled-components'
import { useContext } from 'react'

import { ColesTheme } from '../ColesThemes'
import { getLogoByConsumer } from './ThemedUtils'
import { Link } from '../../text/Link'
import { ChevronLeftIcon } from '../../assets/oneSiteIcons/ChevronLeftIcon'

const Header = styled.div`
  background-color: ${(props) => props.theme.header.backgroundColor};
  position: relative;
  margin: 0;
  height: 79px;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-bottom: 0px solid;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #e8e8e8;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
    border-bottom: ${(props) => props.theme.header.mobileBorderBottom};
  }
`
const HeaderLogo = styled.img`
  height: ${(props) => props.theme.header.logo.height};
  width: ${(props) => props.theme.header.logo.width};
`

const BackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 26px;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: ${(props) => props.theme.header.linkColor};

  ${Link} {
    color: ${(props) => props.theme.header.linkColor};
    &:hover,
    &:focus {
      &::before {
        box-shadow: 0 0 0 2px ${(props) => props.theme.header.linkColor};
      }
    }
  }
`

export interface ThemedHeaderNewProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  backUrl?: string
  backComponent?: React.ReactNode
}

export function ThemedHeaderNew(props: ThemedHeaderNewProps) {
  const theme: ColesTheme = useContext(ThemeContext)
  const logo = getLogoByConsumer(theme.consumer)
  const { backUrl, backComponent, ...filteredProps } = props

  if (backComponent) {
    return (
      <Header {...filteredProps}>
        <BackContainer>
          <ChevronLeftIcon />
          {backComponent}
        </BackContainer>
        <HeaderLogo src={logo} alt={theme.header.logo.alt} role='img' />
      </Header>
    )
  }

  if (!backComponent && backUrl) {
    return (
      <Header {...filteredProps}>
        <BackContainer>
          <ChevronLeftIcon />
          <Link href={backUrl} primary={true}>
            Back
          </Link>
        </BackContainer>
        <HeaderLogo src={logo} alt={theme.header.logo.alt} role='img' />
      </Header>
    )
  }

  return (
    <Header {...filteredProps}>
      <HeaderLogo src={logo} alt={theme.header.logo.alt} role='img' />
    </Header>
  )
}
