import { ScreenReaderText } from './ScreenReaderText'

type SecureLoginIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const SecureLoginIcon = ({ alt, ...props }: SecureLoginIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='18'
      height='21'
      viewBox='0 0 18 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        d='M4.99997 9V4.75398C4.99795 2.82741 6.56911 1.21282 8.63436 1.0192C10.6996 0.825582 12.5869 2.11594 13 4.00398M3 9.00531H15C16.1046 9.00531 17 9.90074 17 11.0053V17.0053C17 18.1099 16.1046 19.0053 15 19.0053H3C1.89543 19.0053 1 18.1099 1 17.0053V11.0053C1 9.90074 1.89543 9.00531 3 9.00531Z'
        stroke='#3D3D3D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { SecureLoginIcon }
