export const NoShowPasswordIcon = () => {
  return (
    <svg
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 1L20 15M8.70402 1.31453C9.4245 1.11462 10.1905 1 11 1C17.3257 1 21 8 21 8C21 8 20.5355 8.88492 19.6525 10.0255M16.6802 12.9862C15.15 14.1211 13.2415 15 11 15C4.67429 15 1 8 1 8C1 8 2.31061 5.50312 4.71392 3.49183M13.364 9.84698C12.344 11.1526 10.4586 11.3841 9.15302 10.364C7.8474 9.34397 7.61591 7.45863 8.63597 6.15302L13.364 9.84698Z'
        stroke='#3D3D3D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
