import styled from 'styled-components'
import { RedCrossCircleErrorIcon } from '../../assets/oneSiteIcons/RedCrossCircleErrorIcon'

export interface ErrorCrossCircleNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const ErrorCrossIcon = styled(RedCrossCircleErrorIcon)`
  margin auto;
  height: ${(props: ErrorCrossCircleNewProps) =>
    props.size ? `${props.size}px` : '74px'};
  width: ${(props: ErrorCrossCircleNewProps) =>
    props.size ? `${props.size}px` : '74px'};
`

export function ErrorCrossCircleNew(props: ErrorCrossCircleNewProps) {
  return <ErrorCrossIcon data-testid='error-cross-circle' {...props} />
}
