import styled from 'styled-components'
import { CloseIcon } from '../../assets/oneSiteIcons/CloseIcon'

export interface CloseIconNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const StyledCloseIcon = styled(CloseIcon)`
  margin: auto;
  height: ${(props: CloseIconNewProps) =>
    props.size ? `${props.size}px` : '8px'};
  width: ${(props: CloseIconNewProps) =>
    props.size ? `${props.size}px` : '8px'};
`

export function CloseIconNew(props: CloseIconNewProps) {
  return <StyledCloseIcon data-testid='close-icon' {...props} />
}
