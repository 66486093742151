import React, { InputHTMLAttributes } from 'react'
import {
  InputFieldContainer,
  InputFieldErrorDecorator
} from './InputFieldStyles'
import { StyledSelectField } from './DropdownInputFieldStyles'
import { RedCircleErrorIcon } from '../assets/oneSiteIcons/RedCircleErrorIcon'
import { InputLabelNew } from './InputLabelNew'
import { ErrorTextNew } from '../text/ErrorTextNew'
import { DescriptionTextNew } from '../text/DescriptionTextNew'

export interface DropdownInputFieldProps
  extends InputHTMLAttributes<HTMLSelectElement> {
  id: string
  options: string[]
  placeholder?: string
  hasError?: boolean
  labelComponent?: React.ReactNode
  labelText?: string
  descriptionText?: string
  errorId?: string
  errorTestId?: string
  errorText?: string
}

export interface InputFieldContainerProps {
  hasError?: boolean
}

export const DropdownInputField = React.forwardRef<
  HTMLSelectElement,
  DropdownInputFieldProps
>((props, ref) => {
  return (
    <React.Fragment>
      {props.labelComponent && props.labelComponent}
      {!props.labelComponent && props.labelText && (
        <InputLabelNew htmlFor={props.id}>{props.labelText}</InputLabelNew>
      )}
      {props.descriptionText && (
        <DescriptionTextNew>{props.descriptionText}</DescriptionTextNew>
      )}
      <InputFieldContainer hasError={props.hasError}>
        <StyledSelectField
          {...props}
          ref={ref}
          defaultValue={props.placeholder ? props.placeholder : undefined}
        >
          {props.placeholder && props.placeholder.length > 0 && (
            <option key={'placeholder'} disabled>
              {props.placeholder}
            </option>
          )}
          {props.options.map((opt, i) => {
            return <option key={i}>{opt}</option>
          })}
        </StyledSelectField>
        {props.hasError && (
          <InputFieldErrorDecorator>
            <RedCircleErrorIcon />
          </InputFieldErrorDecorator>
        )}
      </InputFieldContainer>
      {props.hasError && props.errorText && (
        <ErrorTextNew
          id={props.errorId}
          data-testid={props.errorTestId}
          style={{ paddingTop: '8px' }}
        >
          {props.errorText}
        </ErrorTextNew>
      )}
    </React.Fragment>
  )
})
