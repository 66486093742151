import { successTick } from '../../assets/icons/Icons'
import styled from 'styled-components'

export interface SuccessTickCircleProps {
  size?: number
  centered?: boolean
}

const SuccessTickIcon = styled.img`
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`

export function SuccessTickCircle(props: SuccessTickCircleProps) {
  const size = props.size ?? 74

  return (
    <SuccessTickIcon
      data-testid='success-tick-circle'
      src={successTick}
      height={size}
      width={size}
      alt=''
      role='presentation'
    />
  )
}
