import React from 'react'
import styled, { keyframes } from 'styled-components'
import { LoaderIcon, LoaderIconProps } from '../assets/oneSiteIcons/LoaderIcon'

const spin = keyframes`
 0% {   transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`

const SpinnerImage = styled(LoaderIcon)`
  animation: 0.8s linear 0s infinite normal none running ${spin};
  height: ${(props: SpinnerImageProps) => props.size ?? 22 + 'px'};
  width: ${(props: SpinnerImageProps) => props.size ?? 22 + 'px'};
`

export interface SpinnerProps {
  size: number
  screenReaderMessage?: string
}

interface SpinnerImageProps extends LoaderIconProps {
  size: SpinnerProps['size']
}

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerImage
      data-testid='loaderNew'
      title={props.screenReaderMessage || 'Loading'}
      alt={props.screenReaderMessage}
      role='img'
      aria-live='assertive'
      size={props.size}
    />
  )
}
