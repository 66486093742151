import styled from 'styled-components'
import { useEffect, useRef } from 'react'
import { error } from '../assets/icons/Icons'
import { SuccessTickCircle } from '../common/success-tick-circle/SuccessTickCircle'

export interface BannerProps {
  success: boolean
}

export const BannerContainer = styled.div<BannerProps>`
  border: 1px solid grey;
  border-top: ${(props) =>
    props.success ? '5px solid #008a21' : '5px solid #e01a22'};
  padding-top: 12px;
  padding-bottom: 12px;
`

export const BannerIcon = styled.div`
  display: flex;
  padding-left: 15px;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const BannerContent = styled.div`
  display: flex;
  padding-right: 10px;
`

export const BannerText = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`

export interface ThemedBannerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isHidden?: boolean
  hasError?: boolean
  isWarning?: boolean
  children?: React.ReactNode
}

export function Banner(props: ThemedBannerProps) {
  const errorSize = 20
  const successSize = 20

  const bannerTextRef: any = useRef<HTMLElement>(null)
  useEffect(() => {
    if (!props.isHidden && bannerTextRef !== null) {
      bannerTextRef.current?.focus()
    }
  }, [bannerTextRef, props.isHidden])

  return (
    <div className={props.className}>
      {!props.isHidden && (
        <BannerContainer data-testid='banner' success={!props.hasError}>
          <BannerContent>
            {!props.isWarning && (
              <BannerIcon>
                {!props.hasError ? (
                  <SuccessTickCircle size={successSize} centered={false} />
                ) : (
                  <img
                    src={error}
                    data-testid='error'
                    alt='error icon'
                    width={errorSize}
                    height={errorSize}
                  />
                )}
              </BannerIcon>
            )}
            <BannerText ref={bannerTextRef} tabIndex={0}>
              {props.children}
            </BannerText>
          </BannerContent>
        </BannerContainer>
      )}
    </div>
  )
}
