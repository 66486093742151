import Autocomplete from '@mui/material/Autocomplete'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import { CloseIconNew } from '../common/close-icon/CloseIconNew'
import {
  StyledInput,
  StyledPopper,
  CloseIconContainer,
  ErrorIconContainer
} from './AutocompleteInputFieldStyles'
import { InputLabelNew } from './InputLabelNew'
import { ErrorTextNew } from '../text/ErrorTextNew'
import { InputHTMLAttributes } from 'react'
import { RedCircleErrorIcon } from '../assets/oneSiteIcons/RedCircleErrorIcon'
import { DescriptionTextNew } from '../text/DescriptionTextNew'

const muiTheme = createTheme({})

export interface AutoSuggestOption {
  value: any
  label: string
}

export interface AutocompleteInputFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  autoSuggestOptions: AutoSuggestOption[]
  isAutoCompleteOpen: boolean
  onOpen: (event: React.SyntheticEvent) => void
  onClose: (event: React.SyntheticEvent) => void
  inputChangeHandler: (
    event: React.SyntheticEvent,
    newValue: string,
    reason: string
  ) => void
  onChangeHandler: (
    event: React.SyntheticEvent,
    option: AutoSuggestOption | null,
    reason: string
  ) => void
  clearSelectionHandler: () => void
  inputValue: string
  selectedOption: AutoSuggestOption | null
  noOptionsText: React.ReactNode
  isOptionEqualToValue: (option: AutoSuggestOption, value: any) => boolean
  labelText?: string
  inputFieldId?: string
  hasError?: boolean
  errorText?: string
  descriptionText?: string
}

export const AutocompleteInputField = (props: AutocompleteInputFieldProps) => {
  return (
    <div>
      <ThemeProvider theme={muiTheme}>
        {props.labelText && (
          <InputLabelNew htmlFor={props.inputFieldId}>
            {props.labelText}
          </InputLabelNew>
        )}
        {props.descriptionText && (
          <DescriptionTextNew>{props.descriptionText}</DescriptionTextNew>
        )}
        <Autocomplete
          id={props.inputFieldId}
          inputValue={props.inputValue}
          value={props.selectedOption}
          isOptionEqualToValue={props.isOptionEqualToValue}
          options={props.autoSuggestOptions}
          PopperComponent={StyledPopper}
          open={props.isAutoCompleteOpen}
          onOpen={props.onOpen}
          onClose={props.onClose}
          noOptionsText={props.noOptionsText}
          selectOnFocus={true}
          clearOnBlur={true}
          handleHomeEndKeys={true}
          onInputChange={(event, newValue, reason) => {
            props.inputChangeHandler(event, newValue, reason)
          }}
          onChange={(event, value, reason) => {
            props.onChangeHandler(event, value, reason)
          }}
          renderInput={(params) => (
            <StyledInput
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'chrome-off'
              }}
              error={props.hasError}
              endAdornment={
                <InputAdornment position='end'>
                  {props.inputValue && props.inputValue.length > 0 ? (
                    <CloseIconContainer
                      onClick={() => {
                        props.clearSelectionHandler()
                      }}
                      aria-label='Clear'
                      title='Clear'
                    >
                      <CloseIconNew aria-hidden='true' />
                    </CloseIconContainer>
                  ) : null}
                  {props.hasError ? (
                    <ErrorIconContainer aria-label='Error' title='Error'>
                      <RedCircleErrorIcon aria-hidden='true' />
                    </ErrorIconContainer>
                  ) : null}
                </InputAdornment>
              }
            />
          )}
        />
        {props.hasError && props.errorText && (
          <ErrorTextNew style={{ paddingTop: '8px' }}>
            {props.errorText}
          </ErrorTextNew>
        )}
      </ThemeProvider>
    </div>
  )
}
