import { ScreenReaderText } from './ScreenReaderText'

export type LoaderIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
  title?: string
  'aria-live'?: React.SVGProps<SVGElement>['aria-live']
}

const LoaderIcon = ({ alt, ...props }: LoaderIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        d='M24 13C24 6.92487 19.0751 2 13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24'
        stroke='#212121'
        strokeWidth='4'
        strokeLinecap='round'
      />
    </svg>
  )
}

export { LoaderIcon }
