import styled from 'styled-components'

export const DateOfBirthContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const DobInputColumnContainerDayAndMonth = styled.div`
  flex: 1 1 auto;
  width: 30%;
  padding-right: 8px;
`

export const DobInputColumnContainerYear = styled.div`
  flex: 1 1 auto;
  width: 40%;
`
