import styled from 'styled-components'
import { InputFieldContainerProps } from './InputFieldNew'

export const StyledInputField = styled.input`
  max-width: 100%;
  width: 100%;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  line-height: 24px;
  font-size: 16px;
  padding: 0;

  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: 0px;
  }
`

export const InputFieldContainer = styled.div<InputFieldContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border: ${(props) =>
    props.hasError ? '1px solid #B9091D' : '1px solid #8D8D8D'};
  box-sizing: border-box;
  margin-top: 8px;
  outline: none;
  padding: 12px;
  -webkit-appearance: none;
  border-radius: 4px;
  border-color: ${(props) => (props.hasError ? '#e01a22' : 'transparent')};
  background-color: ${(props) => (props.hasError ? '#FFF5F5' : 'transparent')};
  box-shadow: ${(props) =>
    props.hasError ? '0 0 0 1px transparent' : '0 0 0 1px #8D8D8D'};

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 2px ${(props) => (props.hasError ? '#e01a22' : '#212121')};
    border: 1px solid transparent;
    outline: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const InputFieldErrorDecorator = styled.div`
  flex: 1 1 auto;
  height: 20px;
  width: 20px;
`
