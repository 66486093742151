import { GreenCircleTickIcon } from '../../assets/oneSiteIcons/GreenCircleTickIcon'
import styled from 'styled-components'

export interface SuccessTickCircleNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const SuccessTickIcon = styled(GreenCircleTickIcon)`
  margin: auto;
  height: ${(props: SuccessTickCircleNewProps) =>
    props.size ? `${props.size}px` : '74px'};
  width: ${(props: SuccessTickCircleNewProps) =>
    props.size ? `${props.size}px` : '74px'};
`

export function SuccessTickCircleNew(props: SuccessTickCircleNewProps) {
  return <SuccessTickIcon data-testid='success-tick-circle' {...props} />
}
