import styled from 'styled-components'
import { SecureLoginIcon } from '../../assets/oneSiteIcons/SecureLoginIcon'

export interface SecureLoginIconNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const StyledSecureLoginIcon = styled(SecureLoginIcon)`
  margin: auto;
  height: ${(props: SecureLoginIconNewProps) =>
    props.size ? `${props.size}px` : '18px'};
  width: ${(props: SecureLoginIconNewProps) =>
    props.size ? `${props.size}px` : '16px'};
`

export function SecureLoginIconNew(props: SecureLoginIconNewProps) {
  return <StyledSecureLoginIcon data-testid='secure-login-icon' {...props} />
}
