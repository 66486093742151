import styled from 'styled-components'

export interface InputLabelProps {
  hasError?: boolean
}

export const InputLabel = styled.label<InputLabelProps>`
  display: block;
  color: ${(props) => (props.hasError ? '#e01a22' : 'black')};
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
`
