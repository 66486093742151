import styled, { StyledComponent } from 'styled-components'
import Popper from '@mui/material/Popper'
import InputBase from '@mui/material/InputBase'

export const StyledPopper: StyledComponent<typeof Popper, any> = styled(Popper)`
  & .MuiAutocomplete-paper {
    border: 1px solid #8d8d8d;
    border-radius: 4px;
    margin: 8px 0 8px -2px;
    width: calc(100% + 2px);
  }

  & .MuiAutocomplete-listbox {
    padding: 8px;
  }

  & .MuiAutocomplete-option {
    &[aria-selected=\"true\"],
    &[aria-selected=\"true\"].Mui-focused {
      background-color: #e0e0e0;
    }
    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
`

export const StyledInput = styled(InputBase)`
  border: 1px solid #8d8d8d;
  box-sizing: border-box;
  margin-top: 8px;
  outline: none;
  padding: 8px 12px;
  -webkit-appearance: none;
  border-radius: 4px;
  border-color: transparent;
  background-color: transparent;
  box-shadow: 0 0 0 1px #8d8d8d;
  width: 100%;

  &:focus-within,
  &:hover {
    box-shadow: 0 0 0 2px #212121;
    border: 1px solid transparent;
    outline: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.Mui-error {
    background-color: #fff5f5;
    border: 1px solid #e01a22;
    box-shadow: 0 0 0 1px transparent;
    &:hover {
      box-shadow: 0 0 0 2px #212121;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const CloseIconContainer = styled.button`
  width: 20px;
  height: 20px;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
`

export const ErrorIconContainer = styled.div`
  height: 20px;
  width: 20px;
  margin: 0;
`
