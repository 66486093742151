import styled from 'styled-components'

const Heading2Styled = styled.h2`
  font-family: 'Source Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #212121;
`

export function Heading2New(props: React.HTMLAttributes<HTMLHeadingElement>) {
  return <Heading2Styled {...props} />
}
