import { ScreenReaderText } from './ScreenReaderText'

type MailIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const MailIcon = ({ alt, ...props }: MailIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        d='M4 4L10 9L16 4M3 1H17C18.1046 1 19 1.89543 19 3V13C19 14.1046 18.1046 15 17 15H3C1.89543 15 1 14.1046 1 13V3C1 1.89543 1.89543 1 3 1Z'
        stroke='#3D3D3D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { MailIcon }
