import styled from 'styled-components'
import { loader } from '../assets/icons/Icons'

export interface HideableDivProps {
  isHidden: boolean
}

export const HideableDiv = styled.div<HideableDivProps>`
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`

export interface LoaderProps {
  isShown: boolean
  screenReaderMessage?: string
}

export const LoaderImage = styled.object`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export function Loader(props: LoaderProps) {
  const size = 128
  return (
    <div>
      {props.isShown && (
        <LoaderImage
          data-testid='loader'
          type='image/svg+xml'
          height={size}
          width={size}
          data={loader}
          title={props.screenReaderMessage || 'Loading'}
          role='img'
          aria-live='assertive'
        />
      )}
    </div>
  )
}
