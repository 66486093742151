import styled from 'styled-components'
import { createRef } from 'react'
import { ThemedModal, ThemedModalProps } from './ThemedModal'
import { CloseIcon } from '../../assets/oneSiteIcons/CloseIcon'
import { Heading1New } from '../../headings/Heading1New'
import { ThemedButtonNew } from '../../themed/buttons/ThemedButtonNew'

export interface GenericModalProps extends ThemedModalProps {
  closeHandler: () => void
  titleText?: string
  titleComponent?: React.ReactNode
}

const Heading1NewNoMargin = styled(Heading1New)`
  margin: 0;
`

const ThemedModalFixHeight = styled(ThemedModal)`
  height: fit-content;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;

  &:focus {
    outline: none;
  }
`

const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 12px;
`

const ModalTitleContainer = styled.div`
  flex: 1 1 auto;
`

const ModalCloseIconContainer = styled.div`
  flex: 0 0 auto;
  height: fit-content;
  padding: 4px 8px;
  border: 2px solid transparent;
  border-radius: 16px;
  transition: 0.2s;
  cursor: pointer;

  &:focus,
  &:hover {
    border: 2px solid black;
  }
`

const ModalContentContainer = styled.div`
  padding: 12px 0;
`

const ModalFooterContainer = styled.div`
  padding-bottom: 4px;
  display: flex;
  flex-direction: row-reverse;
`

const ModalCloseButtonContainer = styled.div`
  flex: 0 0 auto;
  padding-top: 12px;
`

export function GenericModal(props: GenericModalProps) {
  const modalContentRef = createRef<HTMLDivElement>()

  return (
    <ThemedModalFixHeight
      {...props}
      onRequestClose={() => {
        props.closeHandler()
      }}
      onAfterOpen={() => {
        modalContentRef.current?.focus()
      }}
      shouldCloseOnEsc={true}
    >
      <ModalContainer ref={modalContentRef} tabIndex={0}>
        <ModalHeaderContainer>
          <ModalTitleContainer>
            {props.titleComponent ? (
              props.titleComponent
            ) : (
              <Heading1NewNoMargin>{props.titleText}</Heading1NewNoMargin>
            )}
          </ModalTitleContainer>
          <ModalCloseIconContainer
            onClick={() => {
              props.closeHandler()
            }}
          >
            <CloseIcon />
          </ModalCloseIconContainer>
        </ModalHeaderContainer>
        <ModalContentContainer>{props.children}</ModalContentContainer>
        <ModalFooterContainer>
          <ModalCloseButtonContainer>
            <ThemedButtonNew
              onClick={() => {
                props.closeHandler()
              }}
            >
              Close
            </ThemedButtonNew>
          </ModalCloseButtonContainer>
        </ModalFooterContainer>
      </ModalContainer>
    </ThemedModalFixHeight>
  )
}
