export const YesShowPasswordIcon = () => {
  return (
    <svg
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 11C12.6569 11 14 9.65686 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65686 9.34315 11 11 11ZM1 8C3.66667 3.33333 7 1 11 1C15 1 18.3333 3.33333 21 8C18.3333 12.6667 15 15 11 15C7 15 3.66667 12.6667 1 8Z'
        stroke='#3D3D3D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
