import styled from 'styled-components'
import { MailIcon } from '../../assets/oneSiteIcons/MailIcon'

export interface MailIconNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const StyledMailIcon = styled(MailIcon)`
  margin: auto;
  height: ${(props: MailIconNewProps) =>
    props.size ? `${props.size}px` : '14px'};
  width: ${(props: MailIconNewProps) =>
    props.size ? `${props.size}px` : '18px'};
`

export function MailIconNew(props: MailIconNewProps) {
  return <StyledMailIcon data-testid='mail-icon' {...props} />
}
