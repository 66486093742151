import { ScreenReaderText } from './ScreenReaderText'

type RedCrossCircleErrorIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const RedCrossCircleErrorIcon = ({
  alt,
  ...props
}: RedCrossCircleErrorIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 24L40 40L24 24ZM40 24L24 40L40 24ZM32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56C38.3652 56 44.4697 53.4714 48.9706 48.9706C53.4714 44.4697 56 38.3652 56 32C56 18.7452 45.2548 8 32 8Z'
        stroke='#B9091D'
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { RedCrossCircleErrorIcon }
