import { ScreenReaderText } from './ScreenReaderText'

type CloseIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const CloseIcon = ({ alt, ...props }: CloseIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 1L9 9L1 1ZM9 1L1 9L9 1Z'
        fill='#B9091D'
      />
      <path
        d='M9 1L1 9M1 1L9 9L1 1Z'
        stroke='#3D3D3D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { CloseIcon }
