import styled from 'styled-components'
import { CloseIconNew } from '../common/close-icon/CloseIconNew'
import { RedTriangleWarningIcon } from '../assets/oneSiteIcons/RedTriangleWarningIcon'

export interface NotificationProps
  extends React.HTMLAttributes<HTMLDivElement> {
  text: string
  isOpen: boolean
  closeHandler: () => void
}

const NotificationContainer = styled.div`
  border: 1px solid #c55307;
  border-radius: 4px;
  background: #fff7f0;
  border-left: 5px solid #c55307;
`

const NotificationContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  align-items: center;
  position: relative;
`

const NotificationTextContainer = styled.div`
  padding: 12px 40px 12px 12px;
`

const CloseIconContainer = styled.div`
  flex: 0 0 auto;
  height: fit-content;
  padding: 8px 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 24px;
`

const WarningIconContainer = styled.div`
  flex: 0 0 auto;
`

export function WarningNotification(props: NotificationProps) {
  return (
    <div>
      {props.isOpen && (
        <NotificationContainer data-testid='notification'>
          <NotificationContentContainer>
            <WarningIconContainer>
              <RedTriangleWarningIcon alt='warning icon' />
            </WarningIconContainer>
            <NotificationTextContainer>{props.text}</NotificationTextContainer>
            <CloseIconContainer
              tabIndex={0}
              onClick={() => {
                props.closeHandler()
              }}
            >
              <CloseIconNew alt='close icon' />
            </CloseIconContainer>
          </NotificationContentContainer>
        </NotificationContainer>
      )}
    </div>
  )
}
