import { useEffect, useRef } from 'react'
import styled from 'styled-components'

export interface Heading1NewProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  announceOnLoad?: boolean
}

const Heading1Styled = styled.h1<Heading1NewProps>`
  font-family: 'Avant Garde';
  font-size: 24px;
  line-height: 32px;

  color: #212121;
`

export function Heading1New(props: Heading1NewProps) {
  const titleRef: any = useRef<HTMLElement>(null)
  useEffect(() => {
    if (props.announceOnLoad && titleRef !== null) {
      titleRef.current?.focus()
    }
  }, [titleRef, props.announceOnLoad])

  return <Heading1Styled {...props} tabIndex={-1} ref={titleRef} />
}
