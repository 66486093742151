import styled from 'styled-components'

import Modal, { Props as ReactModalProps } from 'react-modal'
import { CloseButton } from '../../buttons/CloseButton'

const StyledModal = styled(Modal)`
  position: absolute;
  bottom: 40px;
  top: 40px;
  left: 0;
  right: 0;
  max-width: 458px;
  margin: auto;
  background-color: white;
  border-radius: 0;
  box-shadow: 0px 0px 4px #000;
  outline: none;
  overflow: hidden;
  padding: 12px 0 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    margin: auto 12px;
  }
`

export const ModalHeader = styled.div`
  padding: 0 12px;
`

export const ModalH1 = styled.h1`
  margin-top: 24px;
  margin-right: 36px;
`

export const ModalCloseButton = styled(CloseButton)`
  float: right;
`

export const ModalContent = styled.div`
  overflow-y: scroll;
  margin: 0px;
  padding: 0 12px 12px;
`

export interface ThemedModalProps extends ReactModalProps {
  children?: any
}

export function ThemedModal(props: ThemedModalProps) {
  Modal.setAppElement('#root')

  return (
    <StyledModal
      {...props}
      style={{
        overlay: {
          backgroundColor: 'rgb(0,0,0,0.5)'
        }
      }}
    >
      {props.children}
    </StyledModal>
  )
}
