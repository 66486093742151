import styled from 'styled-components'

export const PasswordFieldLeftContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex: 1 1 auto;
`

export const PasswordFieldRightContainer = styled.button`
  display: flex;
  flex: 0 1 auto;
  width: 80px;
  height: 24px;
  padding-left: 10px;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
`

export const ShowPasswordText = styled.span`
  display: block;
  font-size: 12px;
  text-decoration: underline;
  padding-left: 6px;
  flex: 0 1 auto;
  line-height: 20px;
  margin-top: 2px;
`

export const PasswordIconContainer = styled.div`
  flex: 0 1 auto;
  margin-top: 4px;
`
