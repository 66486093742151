export const RedCircleErrorIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      style={{ padding: '3 0 0 3' }}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14ZM7 7.5C6.44772 7.5 6 7.05228 6 6.5V4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V6.5C8 7.05228 7.55228 7.5 7 7.5ZM8 11H6V9H8V11Z'
        fill='#B9091D'
      />
    </svg>
  )
}
