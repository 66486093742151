import React, { useState } from 'react'
import styled from 'styled-components'
import { InputField } from './InputField'
import { openEye, closedEye } from '../assets/icons/Icons'

export interface PasswordInputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError: boolean
}

const PasswordInput = styled(InputField)`
  padding-right: ${(props: PasswordInputFieldProps) =>
    props.hasError ? '94px' : '68px'};
  background-position: top 8px right 70px;

  ::-webkit-credentials-auto-fill-button {
    margin-right: ${(props: PasswordInputFieldProps) =>
      props.hasError ? '8px' : '0'};
  }
`

const ShowPasswordbutton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  margin-left: -72px;
  margin-top: 8px;
  outline-offset: -4px;
  position: absolute;
  &:before {
    position: absolute;
    right: 40px;
    top: 12px;
  }
`

const ShowPasswordContent = styled.div`
  display: flex;
  color: #4d4d4d;
  font-weight: bold;
  text-decoration: underline;
  width: 58px;
`

const ShowPasswordImage = styled.img`
  margin-right: 6px;
`

export const PasswordInputField = React.forwardRef<
  HTMLInputElement,
  PasswordInputFieldProps
>((props, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const showHidePasswordIcon = isPasswordVisible ? openEye : closedEye
  const showHidePasswordAccessibilityText = isPasswordVisible
    ? 'Hide password'
    : 'Show password'
  const showHidePasswordText = isPasswordVisible ? 'Hide' : 'Show'

  return (
    <div>
      <PasswordInput
        {...props}
        type={isPasswordVisible ? 'text' : 'password'}
        ref={ref}
      />
      <ShowPasswordbutton
        className='show-password-button'
        type='button'
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
      >
        <ShowPasswordContent>
          <ShowPasswordImage
            src={showHidePasswordIcon}
            alt=''
            role='presentation'
          />
          <span aria-label={showHidePasswordAccessibilityText}>
            {showHidePasswordText}
          </span>
        </ShowPasswordContent>
      </ShowPasswordbutton>
    </div>
  )
})
