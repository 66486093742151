import { ThemeProvider } from 'styled-components'

export enum ColesThemeConsumer {
  CDC = 'CDC',
  CUSP = 'CUSP',
  Liqour = 'Liqour',
  CEXP = 'CEXP',
  DrinkSt = 'DrinkSt'
}

export enum ColesClientName {
  ColesApp = 'Coles App',
  ColesExpressApp = 'Coles Express App',
  ColesLiquorApp = 'Coles Liquor App',
  ColesLiquorTest = 'Coles Liquor Test',
  CustomerUnifiedShoppingPlatform = 'Customer Unified Shopping Platform',
  DrinkStApp = 'Drink Street App'
}

export interface ColesTheme {
  consumer: ColesThemeConsumer
  mobileBreakpoint: string
  button: {
    backgroundColor: string
    color: string
    disabled: {
      backgroundColor: string
    }
  }
  secondaryButton: {
    backgroundColor: string
    color: string
  }
  header: {
    backgroundColor: string
    logo: {
      width: string
      height: string
      alt: string
    }
    mobileBorderBottom: string
    linkColor: string
  }
  h2: {
    fontFamily: string
  }
}

export const liquorTheme: ColesTheme = {
  consumer: ColesThemeConsumer.Liqour,
  mobileBreakpoint: '460px',
  button: {
    backgroundColor: '#fedc02',
    color: 'black',
    disabled: { backgroundColor: '#ebcc02' }
  },
  secondaryButton: {
    backgroundColor: 'white',
    color: '#20211f'
  },
  header: {
    backgroundColor: '#e00001',
    logo: {
      width: '219px',
      height: '36px',
      alt: 'Liquorland logo'
    },
    mobileBorderBottom: 'none',
    linkColor: '#ffffff'
  },
  h2: {
    fontFamily: '"Avant Garde", sans-serif'
  }
}

export const cdcTheme: ColesTheme = {
  consumer: ColesThemeConsumer.CDC,
  mobileBreakpoint: '460px',
  button: {
    backgroundColor: '#e01a22',
    color: 'white',
    disabled: { backgroundColor: '#b01319' }
  },
  secondaryButton: {
    backgroundColor: '#fff',
    color: '#20211f'
  },
  header: {
    backgroundColor: '#e01a22',
    logo: {
      width: 'auto',
      height: '36px',
      alt: 'Coles logo'
    },
    mobileBorderBottom: 'none',
    linkColor: '#ffffff'
  },
  h2: {
    fontFamily: '"Avant Garde", "century gothic", arial, sans-serif'
  }
}

export const cuspTheme: ColesTheme = {
  consumer: ColesThemeConsumer.CUSP,
  mobileBreakpoint: '460px',
  button: {
    backgroundColor: '#e01a22',
    color: 'white',
    disabled: { backgroundColor: '#b01319' }
  },
  secondaryButton: {
    backgroundColor: '#fff',
    color: '#20211f'
  },
  header: {
    backgroundColor: '#ffffff',
    logo: {
      width: 'auto',
      height: '36px',
      alt: 'Coles logo'
    },
    mobileBorderBottom: 'none',
    linkColor: '#e01a22'
  },
  h2: {
    fontFamily: '"Avant Garde", "century gothic", arial, sans-serif'
  }
}

export const cexpTheme: ColesTheme = {
  consumer: ColesThemeConsumer.CEXP,
  mobileBreakpoint: '460px',
  button: {
    backgroundColor: '#e01a22',
    color: 'white',
    disabled: { backgroundColor: '#b01319' }
  },
  secondaryButton: {
    backgroundColor: '#fff',
    color: '#20211f'
  },
  header: {
    backgroundColor: 'white',
    logo: {
      width: 'auto',
      height: '40px',
      alt: 'Coles Express logo'
    },
    mobileBorderBottom: '1px solid #e8e8e8',
    linkColor: '#e01a22'
  },
  h2: {
    fontFamily: '"Avant Garde", "century gothic", arial, sans-serif'
  }
}

export const drinkStTheme: ColesTheme = {
  consumer: ColesThemeConsumer.DrinkSt,
  mobileBreakpoint: '460px',
  button: {
    backgroundColor: '#e01a22',
    color: 'white',
    disabled: { backgroundColor: '#b01319' }
  },
  secondaryButton: {
    backgroundColor: '#fff',
    color: '#20211f'
  },
  header: {
    backgroundColor: '#181818',
    logo: {
      width: 'auto',
      height: '40px',
      alt: 'Drink St logo'
    },
    mobileBorderBottom: '1px solid #e8e8e8',
    linkColor: '#fff'
  },
  h2: {
    fontFamily: '"Avant Garde", "century gothic", arial, sans-serif'
  }
}

export const themes = [cdcTheme, cuspTheme, liquorTheme, cexpTheme]

export interface ICCPThemeProps {
  clientName: ColesClientName
  children?: any
}

function getThemeByClientName(clientName: ColesClientName): ColesTheme {
  switch (clientName) {
    case ColesClientName.ColesApp:
    case ColesClientName.CustomerUnifiedShoppingPlatform:
      return cuspTheme
    case ColesClientName.ColesLiquorApp:
    case ColesClientName.ColesLiquorTest:
      return liquorTheme
    case ColesClientName.ColesExpressApp:
      return cexpTheme
    case ColesClientName.DrinkStApp:
      return drinkStTheme
    default:
      return cdcTheme
  }
}

export function CCPTheme(props: ICCPThemeProps) {
  const theme = getThemeByClientName(props.clientName)
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
