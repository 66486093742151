export const ChevronLeftIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 24 24'
    >
      <g fill='none' fillRule='evenodd'>
        <polyline
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          vectorEffect='non-scaling-stroke'
          points='14 18 8 12 14 6'
        ></polyline>
      </g>
    </svg>
  )
}
