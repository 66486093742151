import styled from 'styled-components'
import { useEffect, useRef } from 'react'

export enum H2Type {
  Normal = 'Normal',
  Success = 'Success',
  Failure = 'Failure'
}
export interface H2Props extends React.InputHTMLAttributes<HTMLInputElement> {
  headerType?: H2Type
  children?: any
  announceOnLoad?: boolean
}

const StyledH2 = styled.h2<H2Props>`
  font-family: ${(props) => props.theme.h2.fontFamily};
  color: ${(props) => {
    switch (props.headerType ?? H2Type.Normal) {
      case H2Type.Normal:
        return '#20211F'
      case H2Type.Success:
        return '#008A22'
      case H2Type.Failure:
        return '#DE2419'
      default:
        return '#20211F'
    }
  }}};
`

export function H2(props: H2Props) {
  const titleRef: any = useRef<HTMLElement>(null)
  useEffect(() => {
    if (props.announceOnLoad && titleRef !== null) {
      titleRef.current?.focus()
    }
  }, [titleRef, props.announceOnLoad])

  return <StyledH2 {...props} tabIndex={-1} ref={titleRef} />
}
