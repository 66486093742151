import styled from 'styled-components'
import { cross } from '../assets/icons/Icons'

const Button = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid transparent;

  outline: none;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  overflow: visible;
  &:hover,
  &:focus {
    border-color: black;
    transition: border-color 0.2s;
  }

  &:active {
    border-color: grey;
  }
`

const CloseButtonImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: 0.8s linear 0s infinite normal none running;
`
export interface CloseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: number
  height?: number
}

export function CloseButton(props: CloseButtonProps) {
  const width = props.width ?? 24
  const height = props.width ?? 24

  return (
    <Button {...props}>
      <CloseButtonImage src={cross} alt='Close' width={width} height={height} />
    </Button>
  )
}
