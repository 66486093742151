import { Pagination, PaginationProps } from '@mui/material'

import { ThemeProvider, createTheme } from '@mui/material/styles'

const muiTheme = createTheme({})

export interface PaginationControlProps extends PaginationProps {}

export const PaginationControl = (props: PaginationProps) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Pagination {...props} />;
    </ThemeProvider>
  )
}
