import styled from 'styled-components'
import { Spinner } from './Spinner'

export interface LoaderNewProps {
  isShown: boolean
  screenReaderMessage?: string
}

const Loading = styled.div`
  font-family: 'Avant Garde';
  font-size: 20px;
  line-height: 28px;
  margin-top: 45px;
  margin-bottom: 15px;
`

const JustAFewMoments = styled.div`
  font-size: 16px;
  line-height: 24px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export function LoaderNew(props: LoaderNewProps) {
  const size = 22
  return (
    <div>
      {props.isShown && (
        <LoaderContainer>
          <Spinner
            size={size}
            screenReaderMessage={props.screenReaderMessage || 'Loading'}
          />
          <Loading>Loading</Loading>
          <JustAFewMoments>Just a few moments...</JustAFewMoments>
        </LoaderContainer>
      )}
    </div>
  )
}
