import styled from 'styled-components'
import { HideableDiv, Loader } from '../../loader/Loader'
import { ThemedHeader } from '../headers/ThemedHeader'

export interface UserStepContainerProps {
  children?: any
  showLoader?: boolean
  screenReaderLoadingMessage?: string
}

const UserStepRootDiv = styled.div`
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    height: max-content;
    width: 100%;
  }
  width: 514px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`

const UserStepFeature = styled.div`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #e8e8e8;
  padding: 0 47px 0 47px;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border: none;
  }
  position: relative;
`
export const UserStepContainer = (props: UserStepContainerProps) => {
  // TODO: Check if wrapped in theme provider and throw meaningful error
  const showLoader = props.showLoader ? props.showLoader : false
  return (
    <UserStepRootDiv>
      <ThemedHeader />
      <UserStepFeature>
        <Loader
          isShown={showLoader}
          screenReaderMessage={props.screenReaderLoadingMessage}
        />
        <HideableDiv isHidden={showLoader}>{props.children}</HideableDiv>
      </UserStepFeature>
    </UserStepRootDiv>
  )
}
