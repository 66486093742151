import styled from 'styled-components'
import { RedTriangleWarningIcon } from '../../assets/oneSiteIcons/RedTriangleWarningIcon'

export interface WarningTriangleNewProps {
  size?: number
  alt?: string
  id?: string
  role?: string
}

const WarningTriangleIcon = styled(RedTriangleWarningIcon)`
  margin: auto;
  height: ${(props: WarningTriangleNewProps) =>
    props.size ? `${props.size}px` : '18px'};
  width: ${(props: WarningTriangleNewProps) =>
    props.size ? `${props.size}px` : '20px'};
`

export function WarningTriangleNew(props: WarningTriangleNewProps) {
  return <WarningTriangleIcon data-testid='warning-triangle' {...props} />
}
