import styled, { ThemeContext } from 'styled-components'
import { useContext } from 'react'

import { ColesTheme } from '../ColesThemes'
import { getLogoByConsumer } from './ThemedUtils'

const Header = styled.h1`
  background-color: ${(props) => props.theme.header.backgroundColor};
  margin: 0;
  height: 79px;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-bottom: 0px solid;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #e8e8e8;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
    border-bottom: ${(props) => props.theme.header.mobileBorderBottom};
  }
`
const HeaderLogo = styled.img`
  height: ${(props) => props.theme.header.logo.height};
  width: ${(props) => props.theme.header.logo.width};
`

export function ThemedHeader(props: React.HTMLAttributes<HTMLHeadingElement>) {
  const theme: ColesTheme = useContext(ThemeContext)
  const logo = getLogoByConsumer(theme.consumer)
  return (
    <Header {...props}>
      <HeaderLogo src={logo} alt={theme.header.logo.alt} role='img' />
    </Header>
  )
}
