import styled from 'styled-components'

export const StyledSelectField = styled.select`
  max-width: 100%;
  width: 100%;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  line-height: 24px;
  font-size: 16px;
  padding: 0;

  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: 0px;
  }
`
