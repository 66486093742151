import React, { useState } from 'react'
import { InputFieldNewProps } from './InputFieldNew'
import {
  InputFieldContainer,
  StyledInputField,
  InputFieldErrorDecorator
} from './InputFieldStyles'
import { RedCircleErrorIcon } from '../assets/oneSiteIcons/RedCircleErrorIcon'
import { NoShowPasswordIcon } from '../assets/oneSiteIcons/NoShowPasswordIcon'
import { YesShowPasswordIcon } from '../assets/oneSiteIcons/YesShowPasswordIcon'
import {
  PasswordFieldLeftContainer,
  PasswordFieldRightContainer,
  PasswordIconContainer,
  ShowPasswordText
} from './PasswordInputFieldStyles'
import { InputLabelNew } from './InputLabelNew'
import { DescriptionTextNew } from '../text/DescriptionTextNew'
import { ErrorTextNew } from '../text/ErrorTextNew'

export const PasswordInputFieldNew = React.forwardRef<
  HTMLInputElement,
  InputFieldNewProps
>((props, ref) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)

  const showHidePasswordAccessibilityText = isPasswordHidden
    ? 'Show password'
    : 'Hide password'

  return (
    <React.Fragment>
      {props.labelComponent && props.labelComponent}
      {!props.labelComponent && props.labelText && (
        <InputLabelNew htmlFor={props.id}>{props.labelText}</InputLabelNew>
      )}
      {props.descriptionText && (
        <DescriptionTextNew>{props.descriptionText}</DescriptionTextNew>
      )}
      <InputFieldContainer hasError={props.hasError}>
        <PasswordFieldLeftContainer>
          <StyledInputField
            {...props}
            type={isPasswordHidden ? 'password' : 'text'}
            ref={ref}
          />
          {props.hasError && (
            <InputFieldErrorDecorator>
              <RedCircleErrorIcon />
            </InputFieldErrorDecorator>
          )}
        </PasswordFieldLeftContainer>
        <PasswordFieldRightContainer
          type='button'
          onClick={() => {
            setIsPasswordHidden(!isPasswordHidden)
          }}
        >
          <PasswordIconContainer>
            {isPasswordHidden ? (
              <NoShowPasswordIcon />
            ) : (
              <YesShowPasswordIcon />
            )}
          </PasswordIconContainer>
          <ShowPasswordText aria-label={showHidePasswordAccessibilityText}>
            {isPasswordHidden ? 'Show' : 'Hide'}
          </ShowPasswordText>
        </PasswordFieldRightContainer>
      </InputFieldContainer>
      {props.hasError && props.errorText && (
        <ErrorTextNew
          id={props.errorId}
          data-testid={props.errorTestId}
          style={{ paddingTop: '8px' }}
        >
          {props.errorText}
        </ErrorTextNew>
      )}
    </React.Fragment>
  )
})
