import { ScreenReaderText } from './ScreenReaderText'

type GreenCircleTickIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const GreenCircleTickIcon = ({ alt, ...props }: GreenCircleTickIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        d='M42.6672 24L26.6672 40L20.0005 33.3333M56.0005 32C56.0005 18.7452 45.2553 8 32.0005 8C18.7457 8 8.00049 18.7452 8.00049 32C8.00049 45.2548 18.7457 56 32.0005 56C38.3657 56 44.4702 53.4714 48.9711 48.9706C53.4719 44.4697 56.0005 38.3652 56.0005 32Z'
        stroke='#0DB548'
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { GreenCircleTickIcon }
