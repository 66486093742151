import {
  colesWhiteLogo,
  colesRedLogo,
  liquorLandLogo,
  colesExpressLogo,
  drinkStLogo
} from '../../assets/logos/Logos'

import { ColesThemeConsumer } from '../ColesThemes'

export function getLogoByConsumer(consumer: ColesThemeConsumer): string {
  switch (consumer) {
    case ColesThemeConsumer.CDC:
      return colesWhiteLogo
    case ColesThemeConsumer.CUSP:
      return colesRedLogo
    case ColesThemeConsumer.Liqour:
      return liquorLandLogo
    case ColesThemeConsumer.CEXP:
      return colesExpressLogo
    case ColesThemeConsumer.DrinkSt:
      return drinkStLogo
    default:
      return ''
  }
}
