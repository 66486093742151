import styled from 'styled-components'

export interface ThemedButtonNewProps {
  isSecondary?: boolean
  isFullWidth?: boolean
}

export const ThemedButtonNew = styled.button<ThemedButtonNewProps>`
  background-color: ${(props) =>
    props.isSecondary
      ? props.theme.secondaryButton.backgroundColor
      : props.theme.button.backgroundColor};
  color: ${(props) =>
    props.isSecondary
      ? props.theme.secondaryButton.color
      : props.theme.button.color};
  font-family: 'Source Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  height: 48px;
  line-height: 24px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 1px solid ${(props) => props.theme.button.backgroundColor};
  padding: 12px 24px;
  outline: none;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  min-width: ${(props) => (props.isFullWidth ? '100%' : '48px')};
  overflow: visible;

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    border: 2px solid transparent;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    transition: border-color 0.2s;
  }

  &:hover,
  &:focus {
    &::after {
      border-color: ${(props) => props.theme.button.backgroundColor};
    }
  }

  &:active {
    background-color: #a30006;
    border-color: #a30006;
  }

  &:disabled {
    background: ${(props) => props.theme.button.disabled.backgroundColor};
  }

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    width: 100%;
  }
`
