import { ScreenReaderText } from './ScreenReaderText'

type RedTriangleWarningIconProps = {
  'data-testid'?: string
  alt?: string
  id?: string
  role?: string
}

const RedTriangleWarningIcon = ({
  alt,
  ...props
}: RedTriangleWarningIconProps) => {
  const altText = alt ? <ScreenReaderText>{alt}</ScreenReaderText> : null
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {altText}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.75659 2.24731C8.49993 0.97584 10.1068 0.563547 11.3457 1.32643C11.7139 1.55316 12.0221 1.86943 12.243 2.24731L19.6268 14.8773C20.3701 16.1488 19.9684 17.7979 18.7295 18.5608C18.323 18.8112 17.8577 18.9434 17.3836 18.9434H2.61601C1.17123 18.9434 0 17.7414 0 16.2586C0 15.772 0.128859 15.2945 0.3728 14.8773L7.75659 2.24731ZM10.9998 14.026H8.99977V16.026H10.9998V14.026ZM9.99977 6.02597C9.44749 6.02597 8.99977 6.47368 8.99977 7.02597V11.026L9.0065 11.1426C9.06427 11.6399 9.48694 12.026 9.99977 12.026C10.5521 12.026 10.9998 11.5783 10.9998 11.026V7.02597L10.993 6.90935C10.9353 6.41201 10.5126 6.02597 9.99977 6.02597Z'
        fill='#C55307'
      />
    </svg>
  )
}

export { RedTriangleWarningIcon }
